import { useLocation } from "react-router";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

function MenuItems() {
  const { pathname } = useLocation();

  const DigitalWalletDisplay = () => <p style={{ marginLeft: "48px", marginTop: "50px" }}><img src="./apple-pay-sidebar.png" title="Powered by Mastercard, ApplePay & GooglePay." width="185px" /></p>

  const isMobile = () => {
    if (
      document.getElementsByClassName("mobile-header-logo")[0].offsetWidth > 0
    ) {
      return true;
    }

    return false;
  };

  const closeMenu = () => {
    console.log(isMobile());
    console.log("closing");
    if (isMobile()) {
      let layout = document.getElementsByClassName(
        "ant-layout-sider-zero-width-trigger")[0];
      layout.click();
    }
    return;
  };

  return (
    <Menu
      onClick={() => closeMenu()}
      theme="light"
      mode="inline"
      style={{
        display: "flex",
        fontSize: "17px",
        fontWeight: "500",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "20px"
      }}
      defaultSelectedKeys={[pathname]}
    >
      <Menu.Item key="/quickstart">
        <NavLink to="/quickstart">Dashboard</NavLink>
      </Menu.Item>

      {/* <Menu.Item key="/nftBalance">
        <NavLink to="/nftBalance">MY CASH</NavLink>
      </Menu.Item> */}
      <Menu.Item key="/cardslistfn">
        <NavLink to="/cardslistfn">My Cards</NavLink>
      </Menu.Item>

      {/* <Menu.Item key="/wallet">
        <NavLink to="/wallet">SEND PAYMENT</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="/1inch">
        <NavLink to="/1inch">EXCHANGE</NavLink>
      </Menu.Item> */}
      {/* /* <Menu.Item key="onramp">
        <NavLink to="/onramp">BUY & SELL</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="/erc20balance">
        <NavLink to="/erc20balance">Balances</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="/erc20transfers">
        <NavLink to="/erc20transfers">TRANSACTIONS</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="/contract">
        <NavLink to="/contract">Contract</NavLink>
      </Menu.Item> */}
      <Menu.Item key="pricing">
        <NavLink to={{ pathname: "https://justwallet.com/help_center/topic/debit-card" }} target="_blank">PRICING</NavLink>
      </Menu.Item>
      <Menu.Item key="legal">
        <NavLink to={{ pathname: "https://justwallet.com/cardholder" }} target="_blank">LEGAL</NavLink>
      </Menu.Item>
      <Menu.Item key="faq">
        <NavLink to={{ pathname: "https://justwallet.com/help_center/topic/debit-card" }} target="_blank">FAQ</NavLink>
      </Menu.Item>
      <Menu.Item key="justwallet.com">
        <NavLink to={{ pathname: "https://justwallet.com/" }} target="_blank">JUST WALLET.COM</NavLink>
      </Menu.Item>
      <DigitalWalletDisplay></DigitalWalletDisplay>
      {/* <p style={{ marginLeft: "48px", marginTop: "50px" }}><img src="./apple-pay-sidebar.png" title="Powered by Mastercard, ApplePay & GooglePay." width="185px" /></p> */}
    </Menu>

  );
}

export default MenuItems;
