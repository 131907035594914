import React, { useState } from 'react';
import { Backdrop, makeStyles } from '@mui/material';
import { CircularProgress } from '@mui/material';



const CardGeneratorLoader = () => {
    // const classes = useStyles()
    const [open, setOpen] = useState(false)
    return (
        <div>
            <Backdrop open>
                <img alt="just wallet mastercard" src="/jw-card-loading.gif"></img>
            </Backdrop>
        </div>
    )
}
export default CardGeneratorLoader;