
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";

const useGetUserData = () => {
    const [recordUserToken, setUserAcctToken] = useState("")
    const { account, Moralis } = useMoralis();

    useEffect(() => {
        // console.log('Validating Data against ' + account);
        if (account) {
            FetchUserRecord(account)
        }

    }, [account]);

    const FetchUserRecord = (account) => {

        var acctTokenFromstorage = window.localStorage.getItem("accountToken");
        if (acctTokenFromstorage) {
            // console.log('Account token from Storage ' + acctTokenFromstorage)
            setUserAcctToken(acctTokenFromstorage)
        }
        else {
            const query = new Moralis.Query('UserAccountRecord')
            query.equalTo('userWallet', account);

            query.find()
                .then(function (result) {
                    // console.log('Account token from DB Data ' + result[0]?.get("userAccountToken"))
                    var token = result[0]?.get("userAccountToken");
                    setUserAcctToken(token);
                });
        }
    }


    return recordUserToken;
}

export default useGetUserData;