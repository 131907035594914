import React, { Component } from 'react';
import { TextField, Button } from '@mui/material';
// import { Component } from "react/cjs/react.development";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

class UpdateCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            account_token: this.props.acctToken, // '58a843c9-81c7-4cbe-83c4-a384d5ced062',
            card_token: this.props.cardItem.token, // '2ecba879-fdbb-4aa8-8d1e-0c821e1199a3',
            funding_token: (this.props.cardItem?.funding?.token ?? ""), //'e56c2df2-c2dd-4d92-aa9c-db48d921a926'
            memo: this.props.cardItem.memo,
            spend_limit: this.props.cardItem.spend_limit,
            spend_limit_duration: this.props.cardItem.spend_limit_duration,
            state: this.props.cardItem.state,
            cardPin: ""
        }

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    Update() {

        const { account_token, card_token, funding_token, memo, spend_limit, spend_limit_duration, state, cardPin } = this.state
        const lithicurl = 'api1/updateCard';

        const options = {
            method: 'PUT',
            body: JSON.stringify({
                account_token: account_token, //'58a843c9-81c7-4cbe-83c4-a384d5ced062',
                card_token: card_token,  //'2ecba879-fdbb-4aa8-8d1e-0c821e1199a3',
                // funding_token: funding_token, // 'e56c2df2-c2dd-4d92-aa9c-db48d921a926',
                memo: memo,
                spend_limit: parseFloat(spend_limit),
                spend_limit_duration: spend_limit_duration,
                state: state,
                pin: cardPin
            })
        };

        fetch(lithicurl, options)
            .then(response => {
                if (!response.ok) {
                    console.log('Error Creating Card ' + response.statusText);
                    throw Error('Technical difficulties processing the action.')
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.props.updateCard();
                this.props.closeUpdateCard();
                console.log(response)
            })
            .catch(err => {
                this.props.closeUpdateCard();
                console.error(err)
            });
    }

    handleUpdate = (event) => {
        event.preventDefault();
        const { account_token, card_token, funding_token, memo, spend_limit, spend_limit_duration, state, cardPin } = this.state
        // console.log('Acct token ' + account_token)
        // console.log('Card token ' + card_token)
        // console.log('funding token ' + funding_token)
        // console.log('Memo ' + memo)
        // console.log('Spend limit ' + spend_limit)
        // console.log('Spend duration ' + spend_limit_duration)
        // console.log('State ' + state)
        this.Update();
    }

    render() {
        // const{account_token, card_token, funding_token, memo, spend_limit, spend_limit_duration, state} = this.state
        const { memo, cardPin } = this.state
        // console.log('Acct token ' + account_token)
        // console.log('Card token ' + card_token)
        // console.log('funding token ' +funding_token)
        // console.log('Memo ' + memo)
        // console.log('Spend limit ' + spend_limit)
        // console.log('Spend duration ' + spend_limit_duration)
        // console.log('State ' + state)

        return (
            <div className="update-card-modal">
                <h2>Update Card</h2>
                {
                    <form onSubmit={this.handleUpdate} className="new-card-form">
                        <div className="enroll-field">
                            <TextField name="memo" type="text" label="First  Last Name" defaultValue={memo} onChange={this.handleChange} required></TextField>
                        </div>
                        <div className="enroll-field">
                            <TextField
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                InputLabelProps={{
                                    style: { color: 'white', },
                                }}
                                inputProps={
                                    {
                                        style: { color: "white", width: "250px" },
                                        maxLength: 4, minLength: 4,
                                    }
                                }
                                style={{ color: 'white' }} name="cardPin" type="password" label="PIN" placeholder='UPDATE PIN' defaultValue={cardPin} onChange={this.handleChange} required ></TextField>
                        </div>
                        {/* <div className="enroll-field">
                                <TextField name="spend_limit" label="Spend Limit" type="text" defaultValue={spend_limit} onChange={this.handleChange} required></TextField>
                        </div> */}
                        <div>
                            <InputLabel id="state-select-label">Status</InputLabel>
                            <Select
                                labelId="state-select-label"
                                id="state-select"
                                value={this.state.state}
                                label="Status"
                                onChange={this.handleChange}
                                name="state"
                                className="status-drop"

                            >

                                <MenuItem style={{
                                    color: "black",
                                }} value="OPEN">OPEN</MenuItem>
                                <MenuItem style={{
                                    color: "black",
                                }} value="PAUSED">PAUSED</MenuItem>
                                <MenuItem style={{
                                    color: "black",
                                }} value="CLOSED">CLOSED</MenuItem>

                            </Select>
                        </div>
                        <Button
                            style={{
                                border: "3px solid magenta",
                                color: "white",
                            }}
                            variant="outlined"
                            type='submit'>Update Card</Button>
                    </form>
                }
            </div>
        )
    }

}

export default UpdateCard;