const styles = {
    card: {
        alignItems: "center",

    },
    header: {
        textAlign: "center",
    },

    TextField: {
        color: "white",
        width: "100%",
        outline: "none",
        fontSize: "16px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textverflow: "ellipsis",
        appearance: "TextField",
        color: "#041836",
        fontWeight: "700",
        border: "none",
        backgroundColor: "transparent",
    },
    select: {
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
    },
    textWrapper: { maxWidth: "80px", width: "100%" },
    row: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        flexDirection: "row",
    },
};

export default styles;