import React, { useState } from 'react';


const MobileWallet = () => {
    return (

        <div style={{ marginLeft: "16px", width: "278px" }}></div>

    )
}
export default MobileWallet;