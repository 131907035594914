import React, { useEffect, useState } from 'react';
import Slider from './Slider';
import { TextField, Button, Alert } from '@mui/material';
// // import { notification } from "antd";
import Text from "antd/lib/typography/Text";
import AssetSelector from "../Wallet/components/AssetSelector"
import Account from 'components/Account/Account';
import AddressInput from "./AddressInput";
import { useMoralis, useNFTBalances } from "react-moralis";
// import { useVerifyMetadata } from "hooks/useVerifyMetadata";
import useConversion from "./CoinMarket/useConversion";
import Loader from './CardGeneratorLoader';
import Error from './Error';
import { CalcualteTotalFee } from './Helpers/CardHelpers';



const AddCard = (props) => {

    const [memo, setMemo] = useState("");
    const [spend_limit, setSpendLimit] = useState(0);
    const [cardPin, setPin] = useState("");
    const [spend_limit_duration] = useState("FOREVER");
    const [state] = useState("OPEN");
    const [type] = useState("UNLOCKED");
    const [account_token] = useState(props.acctToken);
    const [funding_token, setFundingToken] = useState("");
    const [tx, setTx] = useState();
    const [asset, setAsset] = useState();

    const { account, isWeb3Enabled, isAuthenticated, Moralis } = useMoralis();
    const [isPending, setIsPending] = useState(false);
    // const { data: NFTBalances } = useNFTBalances();
    // const { verifyMetadata } = useVerifyMetadata();
    const [assetSymbol, setSymbol] = useState("");
    const price = useConversion(assetSymbol, spend_limit);
    let [receiver, setReceiver] = useState();
    // let feePercentage = process.env.REACT_APP_FEE_PERC;
    const [isProcessing, setProcessing] = useState(false);
    const [isError, setError] = useState(false);
    const [errorDesc, setErrorDesc] = useState("");
    const envt = process.env.REACT_APP_ENV;
    const allowedCryptos = process.env.REACT_APP_ALLOWED_CRYPTOS;
    const MaxAmountPerCard = process.env.REACT_APP_MAX_AMOUNT_PER_CARD;
    let tieredFeeData = process.env.REACT_APP_TOKEN_DATA;
    let riskLevels = process.env.REACT_APP_RISK_LEVELS;


    useEffect(() => {
        asset ? setTx({ asset }) : setTx();
        setError(false);
        setErrorDesc("");
        if (envt !== "PRD") {
            getFundingAccount()
        }
        setReceiver(getReceiverAddress)

    }, [account_token, state, spend_limit, asset, isAuthenticated, isWeb3Enabled, account])

    const handleAmountEntry = (e) => {
        e.start = e.target.selectionStart;
        let val = e.target.value;
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        const value = match[1] + match[2];
        e.target.value = value;
        setSpendLimit(value);
        if (val.length > 0) {
            e.target.value = Number(value).toFixed(2);
            e.target.setSelectionRange(e.start, e.start);
            // this.setState({ input: Number(value).toFixed(2) });
            setSpendLimit(Number(value).toFixed(2))
        }

    }

    const updateAsset = (asset) => {
        console.log("Switched asset to " + asset.symbol);
        setSymbol(asset.symbol)
    }

    const spendLimitHandler = (e) => {
        // GetRate(asset);
        setSpendLimit(e.target.value)
    }

    const changeHandler = (e) => {
        setMemo(e.target.value)
    }

    const pinHandler = (e) => {
        setPin(e.target.value)
    }
    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );


    const handleSubmit = (event) => {

        if (parseFloat(spend_limit) > parseFloat(MaxAmountPerCard)) {
            setError(true);
            setErrorDesc("Card amount is limited to $2,500 per card.")
            event.preventDefault();
            return;
        }

        // if ((!isNaN(cardPin) || !isNaN(parseFloat(cardPin)))) {
        //     setError(true);
        //     setErrorDesc("PIN should be a 4 digit number.")
        //     event.preventDefault();
        //     return;
        // }


        var acceptedCryptos = allowedCryptos.split('|');
        if (!acceptedCryptos?.some(item => asset.symbol === item)) {
            setError(true);
            setErrorDesc("We are not supporting the selected asset at this time. Please select a different one!")
            event.preventDefault();
            return;
        }

        else {
            setProcessing(true);
            event.preventDefault();
            var amountInAsset = getTotalAmountinAsset(spend_limit, price);

            if (parseFloat(amountInAsset) == 0 && envt === "PRD") {
                setProcessing(false);
                setError(true);
                setErrorDesc("Unable to Process request. Please enter a valid amount.")
                event.preventDefault();
                return;
            }
            else {
                console.log(asset);
                var totalFee = CalcualteTotalFee(spend_limit, asset);

                console.log('Actual Amount Entered in $ ' + spend_limit)
                console.log('Fee charged $ ' + totalFee)
                console.log('Total amount after adding fee $ ' + getTotalAmount(spend_limit))
                console.log('Aount in Asset ' + amountInAsset)

                if (envt === "PRD") {
                    transfer(amountInAsset, asset).then((response) => {
                        console.log(response);
                        if (response.status === 1) {
                            updateDetailsToDB(account_token, memo, spend_limit, totalFee, amountInAsset, asset.symbol);
                            addNewCard()
                        }
                        else {
                            setProcessing(false)
                            setError(true);
                            setErrorDesc("Transaction Failed. Please try later !")
                        }

                    })
                        .catch(error => {
                            var errorDesc = error?.data?.message;
                            console.log('error', errorDesc);
                            setProcessing(false)
                            setError(true);
                            setErrorDesc(errorDesc)
                        });
                }
                else {
                    updateDetailsToDB(account_token, memo, spend_limit, totalFee, amountInAsset, asset.symbol);
                    addNewCard()
                }

            }
        }
    }

    const getReceiverAddress = () => {
        return process.env.REACT_APP_JW_WALLET_ADDRESS;
    }

    // const getPercentage = (amount) => {

    //     try {
    //         // var percent = getTieredFee(asset);
    //         var percent = CalculateTieredFee(asset.symbol);


    //         var percentAmount = (amount / 100) * percent;
    //         var variableFee = parseFloat(percentAmount).toFixed(2)
    //         var flatFee = parseFloat(getFlatFee()).toFixed(2);
    //         var totalFee = (parseFloat(variableFee) + parseFloat(flatFee)).toFixed(2)
    //         return totalFee
    //     } catch (err) {
    //         console.log('Error while getting percentage fee ' + err)
    //     }
    //     return 0;

    // }

    // const getFlatFee = () => {
    //     try {
    //         var flatFee = process.env.REACT_APP_JW_FIXED_FEE
    //         return flatFee;
    //     } catch (err) {
    //         console.log('Error fetching flat fee' + err);
    //     }
    //     return 0;
    // }

    const getTotalAmount = (amount) => {

        try {
            var percentAmount = parseFloat(CalcualteTotalFee(amount, asset));
            var totalAmount = parseFloat(amount) + parseFloat(percentAmount);
            return parseFloat(totalAmount).toFixed(2);

        } catch (err) {
            console.log('Error while getting Total amount ' + err)
        }
        return 0;

    }

    const getTotalAmountinAsset = (amount, price) => {
        var amountInAsset = 0;

        try {
            if (!isNaN(price) && price !== 0) {
                amountInAsset = (getTotalAmount(amount) / price);
            }

            if (asset) {
                amountInAsset = amountInAsset.toFixed(asset.decimals);
            }

            return amountInAsset;

        } catch (err) {
            console.log('Error while getting Amount in Asset ' + err)
        }
    }

    const updateDetailsToDB = (userAcccount, cardHolderName, amount, feeAmount, cryptoValue, cryptoType) => {
        // console.log('Writing Card Generated data to DB : ' + 'Account - ' + userAcccount + ' Cardholder- ' + cardHolderName + ' Amount- ' + amount + ' feeAmount - ' + feeAmount + ' Cryptoalue - ' + cryptoValue + ' CryptoType -' + cryptoType);
        const userRecord = Moralis.Object.extend('UserCardsRecord')
        const record = new userRecord();
        record.set('userAccountToken', userAcccount);
        record.set('cardHolderName', cardHolderName);
        record.set('principalAmount', amount);
        record.set('feeAmount', feeAmount);
        record.set('amountInCrypto', parseFloat(cryptoValue));
        record.set('cryptoType', cryptoType);

        record
            .save()
            .then(
                (record) => {
                    console.log('Save successful for  ' + userAcccount + ' - ' + record);
                },
                (error) => {
                    console.log('Save Failed for  ' + userAcccount + ' - ' + error);
                }
            );
    }


    const addNewCard = () => {
        const lithicurl = "api1/addCard";
        // console.log('Account token for new card: ' + account_token)
        // console.log('Funding token for new card: ' + funding_token)

        if (account_token === '' | account_token === undefined | account_token === null) {
            console.log('No Account token found for new card: ' + account_token)
            return;
        }

        var rawData = JSON.stringify({
            "memo": memo,
            "spend_limit": parseFloat(spend_limit * 100),// Pass in cents
            "spend_limit_duration": spend_limit_duration,
            "state": state,
            "type": type,
            "account_token": account_token,
            "funding_token": funding_token,
            "pin": cardPin
        });

        var requestOptions = {
            method: 'POST',
            body: rawData,
            redirect: 'follow'
        };

        fetch(lithicurl, requestOptions)
            .then(response => {
                if (!response.ok) {
                    console.log('Error Creating Card ' + response.statusText);
                    throw Error('Technical difficulties processing the action.')
                }
                return response;
            })
            .then(response => response.text())
            .then(result => {
                setProcessing(false)
                props.updateCard();
                if (props.closeNewCard) {
                    props.closeNewCard();
                }
                console.log(result.statusText)

            })
            .catch(error => {
                if (props.closeNewCard) {
                    props.closeNewCard();
                }
                setProcessing(false);
                setError(true);
                setErrorDesc("An error was encountered while creating the card. Please contact our support team for futher assistance. Reference number :" + account_token)
                console.log('error', error);
            });
    }

    const getFundingAccount = () => {
        let lithicfundaccturl = 'api1/getFundingToken';
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        lithicfundaccturl = lithicfundaccturl + '?account_token=' + account_token;
        fetch(lithicfundaccturl, requestOptions)
            .then(response => {
                if (!response.ok) {
                    console.log('Error fetching funding account token ' + response.statusText);
                    throw Error('Technical difficulties processing the action.')
                }
                return response;
            })
            .then(response => response.text())
            .then(result => {
                setFundingToken(JSON.parse(result)[0].token)

            })
            .catch(error => {
                console.log('error', error)
                setFundingToken("");

            });
    }


    // Transfer amount
    const transfer = async (amount, asset) => {
        // const { amount, receiver, asset } = tx;
        let options = {};

        switch (asset.token_address) {
            case "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee":
                options = {
                    native: "native",
                    amount: Moralis.Units.ETH(amount),
                    receiver,
                    awaitReceipt: false,
                };
                break;
            default:
                options = {
                    type: "erc20",
                    amount: Moralis.Units.Token(amount, asset.decimals),
                    receiver,
                    contractAddress: asset.token_address,
                    awaitReceipt: false,
                };
        }

        setIsPending(true);
        const txStatus = await Moralis.transfer(options);
        const result = await txStatus.wait();
        return result;
    }

    const getTieredFee = (asset) => {
        try {
            if (asset != undefined && asset != null) {
                var assetSymbol = asset.symbol.toUpperCase();
                var feeStructure = tieredFeeData;
                const parser = new DOMParser();
                const tieredXml = parser.parseFromString(feeStructure, 'text/xml');
                // var assetFeePerc = tieredXml.querySelector(assetSymbol).getAttribute('FEEPERC');
                var assetRiskLevel = tieredXml.getElementsByTagName(assetSymbol)[0].getElementsByTagName('RISKLEVEL')[0].innerHTML;

                const riskLevelXml = parser.parseFromString(riskLevels, 'text/xml');
                var feePerc = riskLevelXml.getElementsByTagName(assetRiskLevel)[0].innerHTML;


                var assetFeePerc = parseFloat(feePerc);
                return assetFeePerc;
            }
        }
        catch (ex) {
            console.log('Cannot retrieve Asset details for ' + assetSymbol + ' -- Exception ' + ex)
        }


        return 0;

    }

    if (isProcessing) {
        return (
            <Loader setOpen={true} />
        )
    }
    else {
        return (

            <div className='setcard-amount-modal'>
                <h2>Set Card Amount</h2>
                <Slider />
                {
                    isError && <Error errorMessage={errorDesc} />
                }
                {

                    <form onSubmit={handleSubmit} className="new-card-form">

                        <div className="enroll-field">
                            <TextField multiline
                                inputProps={{ style: { color: "white", width: "250px" } }}
                                style={{ color: 'white!important', }}
                                name="Amount on Card"
                                label="Amount on Card in ($)"

                                type="text"
                                // onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]\./g, '') }}
                                value={spend_limit}
                                onChange={handleAmountEntry}
                                required>

                            </TextField>
                        </div>
                        <div className="enroll-field">
                            <TextField
                                InputLabelProps={{
                                    style: { color: 'white', },
                                }}
                                inputProps={{ style: { color: "white", width: "250px" } }}
                                style={{ color: 'white' }} name="Card Name" type="text" label="Card Name" value={memo} onChange={changeHandler} required></TextField>
                        </div>
                        <div className="enroll-field">
                            <TextField
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                InputLabelProps={{
                                    style: { color: 'white', },
                                }}
                                inputProps={
                                    {
                                        style: { color: "white", width: "250px" },
                                        maxLength: 4, minLength: 4,
                                    }
                                }
                                style={{ color: 'white' }} name="PIN" type="password" label="PIN" placeholder='CREATE PIN' value={cardPin} onChange={pinHandler} required ></TextField>
                        </div>

                        {
                            (account && isAuthenticated && isWeb3Enabled) &&
                            <div className="asset-container">
                                <Text
                                    style={{ marginBottom: "15px", color: '#fff!important' }} strong>Payment Method</Text>
                                <AssetSelector
                                    setAsset={setAsset} updateAsset={updateAsset} />
                            </div>
                        }

                        {
                            (account && isAuthenticated && isWeb3Enabled) &&
                            <div className="trans-summary">
                                <div className="summary-item">
                                    <div className="summary-item-title">Load Card</div>
                                    <div className="summary-item-value">${spend_limit != 0 ? spend_limit : 0}</div>
                                </div>
                                <div className="summary-item">
                                    <div className="summary-item-title">Fee</div>
                                    <div className="summary-item-value">${(spend_limit != 0) ? CalcualteTotalFee(spend_limit, asset) : 0}</div>
                                </div>
                                <div className="summary-item">
                                    <div className="summary-item-title">Total</div>
                                    <div className="summary-item-value">${spend_limit != 0 ? getTotalAmount(spend_limit) : 0}</div>
                                </div>
                                <hr style={{ border: "1px solid magenta", minWidth: "100%" }} />
                                <div className="summary-item asset-item-summary">
                                    <div className="summary-item-value">{getTotalAmountinAsset(spend_limit, price)} {assetSymbol}</div>
                                </div>
                            </div>
                        }

                        {
                            (!account && !isAuthenticated && !isWeb3Enabled)
                                ? <Account />
                                : <Button style={{
                                    border: "3px solid #e500ff",
                                    color: "white",
                                    background: "rgba(255, 255, 255, 0)30%)",
                                    width: "80%",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    padding: "10px",
                                    marginTop: "16px"
                                }} disabled={!tx} variant="outlined" type='submit'>Create Card</Button>
                        }

                        {/* {
                            (account && isAuthenticated && isWeb3Enabled) &&
                            <div style={{ marginTop: "24px", fontWeight: "700", fontSize: "16px", width: "100%" }}>
                                <span></span>
                                {asset && <div>Token Price in USD: ${price}</div>}
                                {
                                    <div>Convenience Fee: ${(spend_limit != 0) ? getPercentage(spend_limit) : 0}</div>}
                                <div>Total amount: ${spend_limit != 0 ? getTotalAmount(spend_limit) : 0}</div>
                                <div>Amount in Asset: {price !== 0 ? parseFloat(spend_limit) / price : 0}</div>
                            </div>
                        } */}

                    </form>
                }

            </div>
        )
    }


}
export default AddCard;
