export function CalculateTieredFee(assetSymbol) {
    try {
        if (assetSymbol) {
            var assetSymbol = assetSymbol.toUpperCase();
            var feeStructure = process.env.REACT_APP_TOKEN_DATA;;
            var riskLevels = process.env.REACT_APP_RISK_LEVELS;
            const parser = new DOMParser();
            const tieredXml = parser.parseFromString(feeStructure, 'text/xml');
            const riskLevelXml = parser.parseFromString(riskLevels, 'text/xml');

            var assetRiskLevel = tieredXml.getElementsByTagName(assetSymbol)[0].getElementsByTagName('RISKLEVEL')[0].innerHTML;
            var feePerc = riskLevelXml.getElementsByTagName(assetRiskLevel)[0].innerHTML;

            var assetFeePerc = parseFloat(feePerc);
            return assetFeePerc;
        }
    }
    catch (ex) {
        console.log('Cannot retrieve Asset details for ' + assetSymbol + ' -- Exception ' + ex)
    }

    return 0;
}

export function getFlatFee() {
    try {
        var flatFee = process.env.REACT_APP_JW_FIXED_FEE
        return flatFee;
    } catch (err) {
        console.log('Error fetching flat fee' + err);
    }
    return 0;

}

export function CalculatePercentageFee(amount, asset) {
    try {
        var percent = CalculateTieredFee(asset.symbol);
        var percentAmount = (amount / 100) * percent;
        var variableFee = parseFloat(percentAmount).toFixed(2);
        return variableFee
    } catch (err) {
        console.log('Error while getting percentage fee ' + err)
    }
    return 0;
}

export function CalcualteTotalFee(amount, asset) {
    try {
        if (asset) {
            var variableFee = CalculatePercentageFee(amount, asset);
            var flatFee = parseFloat(getFlatFee()).toFixed(2);
            var totalFee = (parseFloat(variableFee) + parseFloat(flatFee)).toFixed(2);
            return totalFee;
        }

    }
    catch (err) {
        console.log('Error while calculating Total fee amount ' + err)
    }
    return 0;
}