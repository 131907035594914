import { useEffect, createContext, useState } from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Account from "components/Account/Account";
import Chains from "components/Chains";
import TokenPrice from "components/TokenPrice";
import ERC20Balance from "components/ERC20Balance";
import ERC20Transfers from "components/ERC20Transfers";
import DEX from "components/DEX";
import NFTBalance from "components/NFTBalance";
import Wallet from "components/Wallet";
import { Layout, Tabs } from "antd";
import "antd/dist/antd.css";
import NativeBalance from "components/NativeBalance";
import "./style.css";
import QuickStart from "components/QuickStart";
import Contract from "components/Contract/Contract";
import Text from "antd/lib/typography/Text";
import Ramper from "components/Ramper";
import MenuItems from "./components/MenuItems";
import CardsListfn from "components/Cards/CardsListfn";
import Sider from "antd/lib/layout/Sider";
import MobileWallet from "components/Cards/MobileWallet";
import { color, display } from "@mui/system";

const { Header, Footer } = Layout;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "20px",
    padding: "10px",
    flex: "1",
    maxWidth: "100%",
  },
  header: {
    zIndex: 1,
    width: "300px",
    height: "100vh",
    // background: "#1D2024",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    // boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
    flexDirection: "column",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
    flexDirection: "column",
    marginTop: "30px",
  },
  cardsList: {
    width: "80w",
  },
};
const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();
  // const {collapsedMenu, setCollapsedMenu} = useState(false);

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  // console.log('Authenticate flag ' + isAuthenticated)
  //backgroundImage: "url('./background.svg')"

  return (
    <Layout
      style={{
        width: '100vw',
        backgroundColor: "white",
        backgroundSize: "cover",
        flexDirection: "row",
        flexWrap: "wrap",
        // backgroundImage: "url(/aurora-bg.png)"
        backgroundImage: "url(/jw-web-flowing5.jpg)",
        minHeight: '100%',
        backgroundAttachment: "fixed",
        backgroundPsition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Router>
        <div className="mobile-header">
          <img className="mobile-header-logo" src="./jw_white.svg" />
        </div>
        <Sider
          className="siderr"
          // collapsed={true}
          style={{
            height: "100vh",
            left: 0,
            top: 0,
            bottom: 0,
            width: "300px !important",
            maxWidth: "300px !important",
            zIndex: "3",
            minWidth: "330px",
            backgroundColor: "none"
          }}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            if (collapsed == true) {
            }
          }}
        >
          <Header style={styles.header}>
            <Logo />
            <MenuItems />
            <div style={styles.headerRight}>
              <Chains />
              <NativeBalance />
              {/* <div style={{ height: "42px", paddingTop: "-10px", padding: "11px 15px", display: "flex", backgroundColor: "rgb(244, 244, 244)", borderRadius: "12px", width: "fit-content", cursor: "pointer", margin: "auto" }}> */}
              <Account></Account>
              <MobileWallet></MobileWallet>
            </div>
          </Header>
        </Sider>

        <div style={styles.content}>
          <Switch>
            <Route exact path="/quickstart">
              <div>
                <QuickStart isServerInfo={isServerInfo} />
              </div>
            </Route>

            <Route path="/cardslistfn">
              <div className="cards-list">
                <CardsListfn />
              </div>
            </Route>
            <Route path="/wallet">
              <Wallet />
            </Route>
            <Route path="/1inch">
              <Tabs defaultActiveKey="1" style={{ alignItems: "center" }}>
                <Tabs.TabPane tab={<span>Ethereum</span>} key="1">
                  <DEX chain="eth" />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Binance Smart Chain</span>} key="2">
                  <DEX chain="bsc" />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Polygon</span>} key="3">
                  <DEX chain="polygon" />
                </Tabs.TabPane>
              </Tabs>
            </Route>
            <Route path="/erc20balance">
              <ERC20Balance />
            </Route>
            <Route path="/onramp">
              <Ramper />
            </Route>
            <Route path="/erc20transfers">
              <ERC20Transfers />
            </Route>
            <Route path="/nftBalance">
              <NFTBalance />
            </Route>
            <Route path="/contract">
              <Contract />
            </Route>
            <Route path="/">
              <Redirect to="/quickstart" />
            </Route>
            <Route path="/ethereum-boilerplate">
              <Redirect to="/quickstart" />
            </Route>
            <Route path="/nonauthenticated">
              <>Please login using the "Authenticate" button</>
            </Route>
          </Switch>
        </div>

        <Footer
          style={{
            backgroundAttachment: "fixed",
            textAlign: "center",
            backgroundColor: "black",
            height: "0px",
            color: "black",
            display: "none",
            postion: "sticky",
          }}
        >
        </Footer>
      </Router>
    </Layout>
  );
};
export const Logo = () => (
  <div className="jw-logo">
    <img style={{ width: "100%" }} src="./jw_white.svg" />
  </div>
);
export default App;
