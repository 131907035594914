import { useState, useEffect } from "react";

function useConversion(currencySymbol) {
    const [price, setPrice] = useState(0);
    const marginPerc = process.env.REACT_APP_PRICE_MARGIN_PERC;

    useEffect(() => {
        if (currencySymbol) {
            console.log('Fetching rate for : ' + currencySymbol);
            GetRate()
        }
    }, [currencySymbol]);

    function GetRate() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors'
        };

        fetch("api2/getTokenPrice", requestOptions)
            .then(response => {
                if (!response.ok) {
                    console.log('Error fetching account token ' + response.statusText);
                    throw Error('Technical difficulties processing the action.')
                }
                return response;
            })
            .then(function (response) {
                response.json().then((s) => {
                    var returnedPrice = s.data.filter(x => x.symbol === currencySymbol)[0]?.quote?.USD.price;
                    // console.log('Actual ' + returnedPrice)
                    var returnedPriceValue = parseFloat(returnedPrice)
                    var margin = (((parseInt(marginPerc)) / 100) * returnedPriceValue)
                    // console.log('Margin ' + margin)
                    var bakedPrice = (returnedPriceValue - (((parseInt(marginPerc)) / 100) * returnedPriceValue))
                    // console.log('Total ' + bakedPrice)
                    setPrice(bakedPrice)
                });
            })
            .catch(error => {
                setPrice(0);
                console.log('error', error);
            });

    }

    return price
}

export default useConversion;