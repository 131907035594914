import { Card, Timeline, Typography, Button } from "antd";
import React, { useMemo } from "react";
import { useMoralis } from "react-moralis";
import Account from "components/Account/Account";
import styled from "styled-components";
import { width } from "@mui/system";
// import { Button } from '@mui/material';
import { Link } from 'react-router-dom'

const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};

export default function QuickStart({ isServerInfo }) {
  // const { Moralis } = useMoralis();

  // const isInchDex = useMemo(
  //   () => (Moralis.Plugins?.oneInch ? true : false),
  //   [Moralis.Plugins?.oneInch],
  // );

  return (
    <div className="dashboard" style={{ textAlign: "center", padding: "20px" }}>
      <h1 style={{ textAlign: "left", marginLeft: "20px", color: "white", width: "90%" }} className="generate-card">Tap Out of Cryptocurrency</h1>
      <h2 style={{ textAlign: "left", marginLeft: "20px", color: "white", paddingBottom: "50px", fontSize: "1.3rem", width: "95%" }}><p>Introducing The Web3 Just Wallet Card.</p><p>Quickly Exchange Crypto To Prepaid Card and Load to ApplePay & GooglePay.</p></h2>
      <MainContainer>
        <div className="glassbox1">
          <h1 className="generate-card">
            1. Connect Wallet
          </h1>
          <h4 style={{ textAlign: "center", padding: "20px", paddingBottom: "2rem" }}>WORKS WITH 130+ WEB3 WALLETS</h4>
          <br></br>
          <div style={{ height: "42px", padding: "0px 15px", display: "flex", backgroundColor: "rgb(244, 244, 244)", borderRadius: "12px", width: "fit-content", cursor: "pointer", margin: "auto" }}>
            <Account></Account>
          </div>
        </div>
      </MainContainer>
      <MainContainer>
        <div className="glassbox2">
          <h1 className="generate-card">2. Select Amount</h1>
          <h4 style={{ textAlign: "center", padding: "20px", paddingBottom: "2rem" }}>EXCHANGE ETH, WBTC, DAI, AVAX, USDC, USDT, MATIC, TO USD </h4>
          <h4 style={{ textAlign: "center", color: "black" }}>
            <div style={{ height: "42px", padding: "11px 15px", display: "flex", backgroundColor: "rgb(244, 244, 244)", borderRadius: "12px", width: "fit-content", cursor: "pointer", margin: "auto" }}>
              {/* <Button
                style={{ textAlign: "center", letterSpacing: ".2rem!important", fontFamily: "roboto!important" }}
                component={Link} to="/cardslistfn">
                GENERATE MASTERCARD
              </Button> */}
              <Link to="/cardslistfn" className="btn-gen-mastercard">GENERATE PREPAID CARD</Link>
            </div>
          </h4>
        </div>
      </MainContainer>

      <MainContainer><div className="glassbox3">
        <h1 className="generate-card">3. Spend Instantly</h1>
        <img alt="just wallet mastercard " style={{ marginTop: "-40px" }} src="./jw-card-7.png" />
      </div></MainContainer>
    </div >
  );
}
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 22vw;
  padding: 10p;
  margin-right: 20px;
  height: 30vh;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.2rem!important;
  // @media only screen and (max-width: 320px) {
  //   width: 80vw;
  //   height: 90vh;
  //   hr {
  //     margin-bottom: 0.3rem;
  //   }
  //   h4 {
  //     font-size: small;
  //   }
  // }
  // /*MOBILE VIEW PORT*/
  // @media screen and (max-width: 1440px) {
  //   width: 22vw;
  //   margin-right: 20px;
  //   margin-top: 20px;
  //   height: 40vh;
  //   display: inline-block;
  //   vertical-align: -webkit-baseline-middle;
  //   h4 {
  //     font-size: small;
  //     color: #fff
  //   }
  // }
  //SMALL MOBILE
  @media only screen and (min-width: 100px) {
    width: 320PX;
    height: 320PX;
    margin-right: 20px;
    margin-top: 20px;
  }
  // MOBILE
  @media only screen and (min-width: 600px) {
    width: 80WW;
    height: 25VH;
    margin-right: 20px;
    margin-top: 20px;
  }
  // SMALL TABLET
  @media only screen and (min-width: 991px) {
    width: 420PX;
    height: 320PX;
    margin-right: 20px;
    margin-top: 20px;

    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    h4 {
      font-size: small;
      color: #fff
    }
  }
   //TABLET
    @media only screen and (min-width: 1024px) {
      width: 450PX;
      height: 320PX;
    }
    //SMALL DESKTOP
    @media only screen and (min-width: 1280px) {
      width: 20vw;
      height: 45vh;
    }
    // //MOBILE
    // @media only screen and (min-width: 768px) {
    //   width: 22vw;
    //   margin-right: 20px;
    //   margin-top: 20px;
    //   height: 40vh;
    //   display: inline-block;
    //   vertical-align: -webkit-baseline-middle;
    // //TABLET
    //  @media only screen and (max-width: 991px) {
    //    width: 80vw;
    //    height: 20vh;
    //    margin-right: 40px;
    //    margin-top: 20px;
    //  }
    //   h4 {
    //     font-size: 1em;
    //     color: #fff
    //   }
    }
`;
