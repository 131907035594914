import React, { useState } from 'react';
import { Backdrop, makeStyles } from '@mui/material';
import { CircularProgress } from '@mui/material';



const Loader = () => {
    // const classes = useStyles()
    const [open, setOpen] = useState(false)
    return (
        <div>
            <Backdrop open>
                <CircularProgress style={{ 'color': 'white' }}></CircularProgress>
            </Backdrop>
        </div>
    )
}
export default Loader;