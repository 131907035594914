import { Alert } from '@mui/material';

const Error = (props) => {

    const errorMessage = props.errorMessage ?? "There was an error processing the request. Please try again later."
    return (
        <div >
            <Alert style={{ fontSize: ".7REM" }} severity='error'>{errorMessage}</Alert>
        </div>
    )
}

export default Error;