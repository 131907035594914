// const styles = {
//     sliderView: {
//       display: "flex",
//       marginBottom: "24px"

//     }
// }

const Slider = (props) => {
    if (props.firstPage) {
        return (
            <div className="sliderView">
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/icon-default-time@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector-6@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector-7@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector-1@2x.svg" />
            </div>
        )
    } else {
        return (
            <div className="sliderView">
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/icon-default-time@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector-6@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/icon-default-time@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector-6@2x.svg" />
                <img src="https://anima-uploads.s3.amazonaws.com/projects/61de3d9b229651972093dd03/releases/61e73cf837660fab224b68a8/img/vector-1@2x.svg" />
            </div>
        )
    }
}

export default Slider;