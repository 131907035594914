import React, { useState, useEffect } from "react";
import "./cards.css";


const EmbedCard = (props) => {

    const [embed_request, setEmbedRequest] = useState("");
    const [hmac, setHmac] = useState("");
    const [path, setFramePath] = useState("");

    useEffect(() => {
        embedRequestQuery();
        // console.log('Embed Data: ' + embed_request + ' ||| ' + hmac)
    }, [])


    const embedRequestQuery = () => {
        // let apiKey = process.env.REACT_APP_LITHIC_KEY;
        let cardUuid = props.cardTkn;
        let accountToken = props.acctToken

        fetch("api3/embedCard?accountToken=" + accountToken + '&cardUuid=' + cardUuid)
            .then(response => {
                if (!response.ok) {
                    console.log('Error fetching account token ' + response.statusText);
                    throw Error('Technical difficulties processing the action.')
                }
                return response;
            })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                // console.log('Embed Card API ' + data.embed_request + 'hmac ' + data.hmac)
                setEmbedRequest(data.embed_request)
                setHmac(data.hmac)
                setFramePath(process.env.REACT_APP_LITHIC_BASE_URL + '/embed/card?embed_request=' + data.embed_request + '&hmac=' + data.hmac)

                // console.log('Embed Card API ' + data.embed_request + 'hmac ' + data.hmac)
            })
            .catch(error => {
                console.log('error', error);
            });
    }


    return (
        <div className="flip-card-back" >
            <div className="invisible-card"></div>
            <iframe className="card black-card" title="card" src={path}></iframe>
        </div>
    )
}

export default EmbedCard;