import { CreditCardFilled } from "@ant-design/icons";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from 'moment';

const Transactions = (props) => {

  const [trans, setTrans] = useState([])
  const [balance, setBalance] = useState(0);
  const [selectedLastFour, setLastFour] = useState("")
  useEffect(() => {
    // console.log('Transactions')
    getTransactions(props)

  }, [props.cardTkn, props.acctToken])


  async function getTransactions(props) {
    let transactionsUri = "api1/getTrans"
    //Form filter for all/Approved/Reject and read account token and card token from props 2ecba879-fdbb-4aa8-8d1e-0c821e1199a3
    transactionsUri = transactionsUri + "?card_token=" + props.cardTkn + "&account_token=" + props.acctToken
    const options = {
      method: 'GET',
    };

    try {
      var response = await fetch(transactionsUri, options);
      if (response.ok) {
        var transactions = await response.json();
        var currentCardLimit = props.selectedCard.spend_limit;
        var spentAmount = transactions?.data?.filter(({ status }) => (status === 'PENDING' | status === "APPROVED" | status === "SETTLING" | status === "SETTLED"))?.reduce((sum, record) => sum = sum + record.amount, 0)
        var balanceAmount = parseFloat((parseFloat(currentCardLimit) - parseFloat(spentAmount)) / 100).toFixed(2);
        setBalance(balanceAmount);
        // console.log(spentAmount)
        setTrans(transactions.data);
        setLastFour(props.selectedCard.last_four);
      } else {
        console.log('Error fetching account token ' + response.statusText);
        throw Error('Technical difficulties processing the action.')
      }

    }
    catch (err) {
      console.log('Error fetching account token ' + err.statusText);
      throw Error('Technical difficulties processing the action.')
    }
  }

  function formatDate(date) {
    var formattedDate = new Date(date);
    return moment(formattedDate).format("MMM D YYYY HH:mm");
  }

  return (
    <div className="transactions">
      <Typography variant="h6">Activity</Typography>
      <span>Card ending : {selectedLastFour}</span>
      <br></br>
      <span>Available Balance : ${balance}</span>
      <table className="transactions-table">
        {/* <thead>
                <tr>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead> */}
        <tbody>
          {
            (trans.length > 0) ? trans.map(tran =>
              <tr className="tran" key={tran.token}>
                <td>
                  <CreditCardFilled className="credit-card tran-merchant" /> {tran.merchant.descriptor}
                </td>
                <td className="tran-location">
                  {tran.merchant.country + '/' + tran.merchant.state + '/' + tran.merchant.city}
                </td>
                <td className="tran-date">
                  {formatDate(tran.created)}
                </td>
                <td className="status">
                  {tran.status}
                </td>
                <td className="bold">
                  ${parseFloat(tran.amount / 100).toFixed(2)}
                </td>

              </tr>
            ) :
              <tr className="tran" >
                <td>
                  NO TRANSACTIONS FOR THIS CARD
                </td>
              </tr>

          }
        </tbody>
      </table>

      {/* {
              trans.map(tran => 
                <div className="tran" key={tran.token}>


                  <div>
                    Amount : {tran.amount / 100}
                  </div>
                  <div>
                    Description : {tran.merchant.descriptor}
                  </div>
                  <div>
                    Status : {tran.status}
                  </div>
                  <div>
                    Date : { tran.created}
                  </div>
                  
                </div>


              )
            } */}
      <img className="applepay-logo" src="./applepay-googlepay-web3.png" title="Open the ApplePay or GooglePay wallet app and scan or manually enter the card details. " />
    </div>
  )
}

export default Transactions;