import { EditOutlined } from "@ant-design/icons";
import { Box, Modal, Typography } from "@mui/material";
// import { render } from "@testing-library/react";
import React, { useState } from "react";
import "./cards.css";
import { CreditCardFilled } from "@ant-design/icons";




const StaticCard = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        borderRadius: 4,
        p: 4,
    };
    const cardsFiltered = props.cardData?.sort((a, b) => new Date(b.created) - new Date(a.created))
        .filter(x => x.state === 'OPEN');
    const [cardTkn, setCardToken] = useState(cardsFiltered[0].token);
    // const [cardTkn, setCardToken] = useState();
    const [openUpdateCard, setOpenUpdateCard] = useState(false);
    const [openNewCard, setOpenNewCard] = useState(false);

    const [cardItem, setCardItem] = useState([]);

    const [showBackCard, setFlipSide] = useState({});

    const handleOpenUpdateCard = () => setOpenUpdateCard(true);
    const handleCloseUpdateCard = () => setOpenUpdateCard(false);

    const handleOpenNewCard = () => {
        if (props.acctToken !== "12345678910") {
            setOpenNewCard(true);
        }
    }
    const handleCloseNewCard = () => setOpenNewCard(false);

    const toggleCardFlip = (token) => {
        let temp = { ...showBackCard };
        if (temp[token]) {
            temp[token] = false;
        } else {
            temp[token] = true;
        }
        setFlipSide(temp);
        console.log(showBackCard)
        console.log(cardItem)
    }


    function handleCardClick(cardTkn, acctToken, cardItem) {
        setCardToken(cardTkn);
        setCardItem(cardItem);


    }



    return (

        <React.Fragment>
            <div className="card-list-left">
                <div className="card-title-container">
                    <Typography variant="h6">Demo Cards</Typography>
                </div>
                <div className="contain-all-cards">

                    {
                        cardsFiltered.map((card, index) =>
                        (
                            <div key={card.token} className={"card-container-iframe " + ((card.token === cardTkn) ? 'selected' : '')} onClick={() => handleCardClick(card.token, props.acctToken, card)} id={index}>
                                <div className="flip-card" onClick={() => toggleCardFlip(card.token)}>
                                    <div className={"flip-card-inner flipping" + ((showBackCard[card.token]) ? 'flipping' : '')}>
                                        <div className={"card flip-card-front"} >
                                            <div className='card__top'>
                                                <div>{card.state}</div>
                                                <div>${card.spend_limit / 100}</div>
                                            </div>
                                            <div className='card__bottom'>
                                                <div>{card.memo} </div>
                                                <div>{card.pan.slice(card.pan.length - 4)}</div>
                                            </div>
                                        </div>

                                        <div className="flip-card-back" >
                                            <div className="invisible-card"></div>
                                            <link rel="stylesheet" type="text/css" href="/web3card.css"></link>
                                            <div className="card black-card" title="Click to Copy" >
                                                <div id="card">
                                                    <div id="pan" >5412<span className="pan-separator"></span>7500<span className="pan-separator"></span>1234<span className="pan-separator"></span>9876</div>
                                                    <div id="expiry">
                                                        <span id="month" >00</span>
                                                        <span id="separator">/</span>
                                                        <span id="year" >00</span>
                                                    </div>
                                                    <div id="cvv" >000</div>
                                                    <div id="alert" className="empty"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        ))

                    }
                </div>
            </div>

            <div className="transactions-container">
                <div className="transactions">
                    <Typography variant="h6">Activity</Typography>
                    <span>Card ending : 9876</span>
                    <br></br>
                    <span>Available Balance : $60.00</span>
                    <table className="transactions-table">
                        <tbody>
                            <tr className="tran" >
                                <td className="tran-merchant">
                                    <CreditCardFilled className="credit-card" /> {"WALMART"}
                                </td>
                                <td className="tran-location">
                                    {"USA/NY/NY"}
                                </td>

                                <td className="status">
                                    {"SETTLED"}
                                </td>
                                <td className="bold">
                                    $30.00
                                </td>
                            </tr>
                            <tr className="tran" >
                                <td className="tran-merchant">
                                    <CreditCardFilled className="credit-card" /> {"PAYPAL"}
                                </td>
                                <td className="tran-location">
                                    {"USA/NY/NY"}
                                </td>

                                <td className="status">
                                    {"PENDING"}
                                </td>
                                <td className="bold">
                                    $10.00
                                </td>
                            </tr>
                            <tr className="tran" >
                                <td className="tran-merchant">
                                    <CreditCardFilled className="credit-card" /> {"AMAZON"}
                                </td>
                                <td className="tran-location">
                                    {"USA/NY/NY"}
                                </td>

                                <td className="status">
                                    {"VOID"}
                                </td>
                                <td className="bold">
                                    $0.00
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>



        </React.Fragment>

    );
};
export default StaticCard;

